.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}

html {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    overflow: auto;
}

body {
    padding: 1rem;
    overflow: auto;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#gridWrapper {
  width: 100%;
  height: 100%;
}

.searchFieldWrapper {
  line-height: 3;
  font-weight: bold;
}

#gridFilterBox {
  font-weight: bold;
}

.vacancyRowCell{
    display: flex;
}

.vacancyRowCell p {
    margin: 0 0 0 10px;
}

.vacancyRowCell img {
    max-width: 42px;
    max-height: 42px;
}

.ag-cell-wrapper{
    height: auto;
    max-height: 100%;
    padding-top: 6px;
}

.ag-cell-wrapper::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.ag-cell-wrapper::-webkit-scrollbar-corner {
  background: transparent;
}
.ag-cell-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}
[col-id="customRendered_1"] .ag-cell-wrapper, [col-id="customRendered"] .ag-cell-wrapper{
    overflow: hidden;
}
[col-id="customRendered_1"] .ag-cell-value{
    margin-top: -6px;
}
