body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
monospace;
}

#whole-app {
padding-bottom: 50px;
}

.filter-block {
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap: 8px;
margin-bottom: 8px;
}
@media (max-width:500px){
	.filter-block {
		flex-direction: column;
	}
}
.filter-control {
flex-basis: 48%;
flex-grow: 1;
}

.filter-control .MuiFormControl-root {
width: 100%;
}

#grid-wrapper {
}

/* to prevent shrinking width when there isn't much content in the cell */
.ag-cell-value {
width: 100%;
}

.filter-select-box {
min-width: 14rem;
}

.cand-detail-row {
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 1rem;
}

.cand-detail-block1 {
/*flex-basis: 50%;*/
}

.cand-detail-block2 {
align-self: flex-start;
width: 12rem;
min-width: 12rem;
max-width: 12rem;
white-space: nowrap;
}

.cand-block-elem {
display: flex;
padding-top: 2px;
padding-bottom: 2px;
}

.cand-block-elem-title {
white-space: nowrap;
padding-right: 4px;
}

.cand-block-elem-values {
display: flex;
flex-wrap: wrap;
overflow: scroll;
max-height: 64px;
}

.cand-block-elem-values div {
margin-top: 4px;
}

.MuiChip-root {
margin-left: 2px;
margin-right: 2px;
border-radius: 10px !important;
height: 28px;
}

.grid-row {
background-color: lightyellow;
cursor: pointer;
}


/* Min width when it makes sense to display both blocks in one row */
@media (min-width: 640px) {
.cand-detail-row {
	flex-direction: row;
}

.cand-detail-block1 {
	min-width: 0;
}

.cand-detail-block2 {
	align-self: flex-end;
}
}

/* WHOLE APP CSS */

body{
    background: url("background.png");
	background-size: 100% 100%;
}

/* FILTERS */

/* filters inputs */

@media(max-width:500px){
.filter-block .MuiOutlinedInput-input{
padding-top: 8px;
padding-bottom: 8px;
}
.filter-block .MuiOutlinedInput-adornedEnd{
    padding: 0 !important;
}
}


.filter-block .MuiInputBase-root,.MuiOutlinedInput-root{
font-size: 20px;
font-weight: 500;
}
.filter-block .MuiInputBase-root,.MuiOutlinedInput-root,.filter-select-box,.MuiInputBase-formControl{
border-radius: 14px;
background-color: rgba(245, 245, 245, 0.8);
}

.filter-block .PrivateNotchedOutline-root-1,.MuiOutlinedInput-notchedOutline{
border: none;
}

/* filters labels */
.filter-block .MuiFormLabel-root,.MuiInputLabel-root,.MuiInputLabel-formControl,.MuiInputLabel-animated,.MuiInputLabel-outlined{
font-size: 19px;
font-weight: 500;
opacity: 0.6;
color: black;
z-index: 1 !important;
transform: translate(14px, 20px) scale(1) !important;
pointer-events: none !important;
}
@media(max-width:500px){
.filter-block .MuiInputLabel-outlined{
    transform: translate(14px, 10px) scale(1) !important;
}
}

.filter-block .MuiInputLabel-root,.MuiInputLabel-formControl,.MuiInputLabel-animated,.MuiInputLabel-shrink,.MuiInputLabel-outlined,.Mui-focused,.Mui-focused{
	color: black;
}
.filter-block .MuiFormLabel-root.Mui-focused {
color: transparent;
}
.filter-block .MuiFormLabel-filled{
color: transparent;
}

 .filter-block .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: transparent;
}

/* filters dropdown */
.filter-block .MuiPaper-root,.MuiMenu-paper,.MuiPopover-paper,.MuiPaper-elevation8,.MuiPaper-rounded{
top: 73px !important;
}

/* filters checkbox */
.filter-block .checkbox .filter-control{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}

.filter-block .container{
display: block;
position: relative;
padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.filter-block .container p{
    margin: 0;
    font-size: 21px;
font-weight: 500;
opacity: 0.6;
padding-left: 5px;
padding-top: 3px;
color: white;

}

.filter-block .container input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

.filter-block .checkbox .filter-control input ~ .checkmark {
 background-color: #ccc;
}

.filter-block .checkbox .container input:checked ~ .checkmark {
background-color: #2196F3;
}

.filter-block .container input:checked ~ .checkmark:after {
  display: block;
}

.filter-block .container .checkmark:after {
left: 9px;
top: 5px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.filter-block .checkmark {
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: #eee;
border-radius: 100%;
border: 4px solid rgba(245, 245, 245, 0.8);
}

.container:hover input ~ .checkmark {
background-color: #ccc;
}

.filter-block .checkmark:after {
content: "";
position: absolute;
display: none;

}

/* GRID */
.ag-theme-alpine .ag-root-wrapper{
border-radius: 14px;
border-color: #E0E0E1 !important;
}

.ag-theme-alpine .ag-header{
border-bottom-color: #E0E0E1 !important;
}
.ag-theme-alpine .ag-header-row{
background-color: #F4F4F4;
}

.ag-theme-alpine .ag-header-cell-label{
height: 40px;
    padding: 0px 20px;
    font-size: 10px;
    font-family: "Proxima Nova Semibold", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.36px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.ag-theme-alpine .ag-cell-value{
    font-family: "Proxima Nova Semibold", Roboto, Helvetica, Arial, sans-serif;
        font-weight: 300;
}

.ag-theme-alpine .ag-cell:nth-of-type(1) .ag-cell-value,.ag-theme-alpine .ag-cell:nth-of-type(4) .ag-cell-value{
font-weight: 500;
}

.ag-theme-alpine .ag-react-container .vacancyRowCell{
height: 42px;
border: 0px solid currentcolor;
border-radius: 14px;
align-items: center;
}

.ag-theme-alpine .ag-react-container .vacancyRowCell img {
border-radius: 7px;
object-fit: contain;
}

.ag-theme-alpine .ag-paging-panel{
border-top-color: #E0E0E1 !important;
}

/* Paging Panel*/
.ag-paging-panel .ag-paging-row-summary-panel,.ag-paging-page-summary-panel{
    font-family: "Proxima Nova Semibold", Roboto, Helvetica, Arial, sans-serif;
    opacity: 0.6;
}

/* Grid Checkbox*/
.remoteRowCell input{
    display: none;

}
.remoteRowCell label{
 width: 32px;
 height: 26px;
 display: block;
 position: relative;
}
.remoteRowCell input[type="checkbox"]:checked + span  	{
 position: absolute;
 left: 0; top: 0;
 width: 100%; height: 100%;
 background: url(checked.svg) no-repeat;
}

/* Grid Location and Tags*/
.ag-cell-wrapper{
   overflow: scroll;
}
.ag-cell-wrapper .ag-cell-value{
	line-height: 20px !important;
	padding-top: 10px;
	padding-bottom: 10px;
}
@media(max-width:700px){
.ag-cell-wrapper{
	height: unset !important;
	padding: 0!important;
}
	.ag-cell-wrapper .ag-cell-value{
	padding: 0!important;
	}

}

/* Mobile Grid*/
#grid-wrapper .mobileCell{
	display: flex;
	    flex-direction: row;
}


#grid-wrapper .mobileCell .mobileCell-logo{
    width: 35%;
	display: flex;
    flex-direction: row;
    align-items: center;
}
#grid-wrapper .mobileCell .mobileCell-logo img{
    width: 100%;
	object-fit: contain;
	    max-height: 112px;
		    margin: auto;padding-top: 10px;
    height: 82px;
    padding-bottom: 10px;

}

#grid-wrapper .mobileCell .mobileCell-description{
width: 65%;

    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
	    padding-left: 25px;
		padding-top: 15px;

}


#grid-wrapper .mobileCell .mobileCell-description p{

margin:0;
font-size: 18px;
    opacity: 70%;
}
#grid-wrapper .mobileCell .mobileCell-description h3{
	position:relative;
	font-size: 20px;
margin: 7px 0;
max-width: calc(100% - 25px);
}

#grid-wrapper .mobileCell::after{
position: absolute;
content:"";
background : url(arrow-link.svg) no-repeat;
width: 24px;
height: 24px;
top: 45px;
right: 25px;

}


@media(max-width:700px){
#grid-wrapper .ag-header-cell-label{
	display: none;

}

#grid-wrapper .ag-cell{

}

#grid-wrapper .ag-header{
display: none;
}
}
